import React from 'react';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import axios from 'axios';
import Input from "../../generic/Input";
import {debounce} from 'lodash';
import {API} from "../../../constants";
import request from 'axios';
import PriceInput from "../../generic/PriceInput";
import Dropzone from "react-dropzone";
import Asset from "./Asset";
import Toggle from "../../generic/Toggle";
import PendingAsset from "./PendingAsset";

class Cut extends React.Component {

  constructor(props) {
    super(props);
    const {name, notes, createdAt, updatedAt, price, Assets, releasedAt} = this.props.data;
    this.state = {
      name,
      notes,
      price,
      createdAt,
      updatedAt,
      released: !!releasedAt,
      assets: Assets || [],
      uploads: [],
      pending: false,
    }
  }

  handleNameChange = ({target}) => {
    this.setState({name: target.value.trim(), pending: true});
    this.persist();
  };

  handleNotesChange = ({target}) => {
    this.setState({notes: target.value, pending: true});
    this.persist();
  };

  handlePriceChange = (value) => {
    this.setState({price: value, pending: true});
    this.persist();
  };

  handleReleasedToggle = () => {
    this.setState({released: !this.state.released}, () => {
      this.persistNow();
    });
  };

  handleAssetDelete = id => {
    this.setState({
      assets: this.state.assets.filter(a => a.id !== id)
    })
  };

  handleDelete = () => {
    if (window.confirm(`Delete cut "${this.state.name}"?`)) {
      request.delete(`${API}/cut/${this.props.data.id}`)
        .then(() => {
          this.props.onDelete(this.props.data.id)
        })
        .catch(e => {
          console.error(e);
          alert("Failed to delete cut")
        })
    }
  };


  handleFileSubmit = (files) => {
    console.log(files);
    const tempId = nanoid();
    const formData = new FormData();
    const uploads = [];
    files.forEach(file => {
      formData.append('assets', file);
      uploads.push({
        tempId,
        name: file.name,
        progress: 0
      })
    });
    this.setState({
      uploads: [...this.state.uploads, ...uploads]
    });
    axios.post(`${API}/asset/upload/${this.props.data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progress => {
        this.setState({
          uploads: this.state.uploads.map(u => ({
            ...u,
            progress: u.tempId === tempId ? Math.floor((progress.loaded / progress.total) * 100) : u.progress
          }))
        })
      }
    })
      .then(res => {
        console.log(res)
        this.setState({
          uploads: this.state.uploads.filter(item => item.tempId !== tempId),
          assets: [...this.state.assets, ...res.data]
        })
      })
      .catch(e => {
        console.error(e);
        this.setState({
          uploads: this.state.uploads.filter(item => item.tempId !== tempId)
        });
        alert('Asset upload error')
      })
  };

  persistNow = () => {
    const data = {
      id: this.props.data.id,
      name: this.state.name,
      notes: this.state.notes,
      price: this.state.price,
      releasedAt: this.state.released ? new Date().toISOString() : null
    };
    request.patch(`${API}/cut`, data)
      .then(res => {
        this.setState({pending: false})
      })
      .catch(e => {
        console.error(e);
        alert("Error persisting cut data.")
      })
  };

  persist = debounce(this.persistNow, 500);

  render() {
    const {name, notes, pending, price, assets, released, uploads, createdAt, updatedAd} = this.state;
    return (
      <div className="cut-container">
        <div className="cut-content-container">
          <div className="cut-meta-container">
            <div className="cut-meta-fields">
              <Input
                onChange={this.handleNameChange}
                placeholder="Untitled Cut"
                pending={pending}
                value={name}
              />
              <Input
                onChange={this.handleNotesChange}
                placeholder={"📝 Notes"}
                pending={pending}
                value={notes}
                style={{color: '#565656'}}
              />
              <PriceInput
                onChange={this.handlePriceChange}
                value={price}
                pending={pending}
              />
              <Toggle
                onToggle={this.handleReleasedToggle}
                label="Released"
                active={released}
              />
            </div>
            <div className="button-container-small">
              <button onClick={this.handleDelete} className="medium">Remove Cut</button>
            </div>
          </div>
          <div className="cut-assets-container">
            {
              uploads.map((u, idx) =>
                <PendingAsset key={idx} name={u.name} progress={u.progress}/>
              )
            }
            {
              assets.map(a =>
                <Asset onDelete={this.handleAssetDelete} key={a.id} data={a}/>
              )
            }
            <Dropzone onDrop={this.handleFileSubmit}>
              {({getRootProps, getInputProps, isDragActive}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <p className="dropzone drag-active">Drop here to upload!</p> :
                        <p className="dropzone">Drop Asset / Click to Browse</p>
                    }
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    )
  }
}

Cut.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
    notes: PropTypes.string,
    price: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    releasedAt: PropTypes.string
  }),
  onDelete: PropTypes.func.isRequired
};

Cut.defaultProps = {
  releasedAt: null
};

export default Cut;
