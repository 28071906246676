import React from 'react';
import PropTypes from 'prop-types'

class Input extends React.Component {

  render() {
    const {value, onChange, placeholder, pending, style, type, name} = this.props;
    return (<input
      type={type}
      placeholder={placeholder}
      className={`seamless-input ${pending ? 'pending' : ''}`}
      onChange={onChange}
      value={value || ''}
      name={name}
      style={style}
    />)
  }
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string
};

Input.defaultProps = {
  value: '',
  placeholder: '',
  type: 'text',
  name: 'input'
};

export default Input;
