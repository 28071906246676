import React from 'react';
import PropTypes from 'prop-types'

class PendingAsset extends React.Component {

  render() {
    const {progress, name} = this.props;
    return (
      <div className="asset-container">
        <div className="asset-name">
          {progress}% Uploading {name}...
        </div>
        <div className="asset-actions">
          <div className="asset-action" title="CDN-Options (public)">🚧</div>
        </div>
      </div>
    )
  }
}

PendingAsset.propTypes = {
  name: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired
};

export default PendingAsset;
