import React from 'react'
import {usePagination, useTable} from 'react-table'
import request from "axios";
import {API, formatVat} from "../../../constants";
import Dinero from 'dinero.js';
import {Link} from "react-router-dom";

function download(filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


const dateCell = ({value}) => new Date(value).toLocaleDateString();

const idCell = ({value}) => (<a href={`/orders/${value}`} title={value}>{value.split('-')[0]}</a>);

const moneyCell = ({value}) => value ? Dinero({amount: value, currency: 'EUR'}).toFormat() : 'None';

const taxLabel = (row) => {
  if(row.vatAmount){
    return `${moneyCell({value: row.vatAmount})} (${formatVat(row.vatRate)}%)`
  }
  if(row.vatCode && row.vatArea === "worldwide"){
    return "Business World"
  }
  if(row.vatCode){
    return "Business EU"
  }
  if(row.vatArea){
    return "Consumer World"
  }
  return "???"
};

const statusAccessor = (row) => {
  if (row.isIgnored) {
    return '`👻 Ignored'
  }
  if (row.hasError) {
    return '🚨 Error Occurred'
  }
  if (row.manualReview) {
    if (!row.paidAt || !row.fulfilledAt) {
      return '❗️️ Action Required'
    }
    return '✔️ Done (Manual)'
  } else {
    if (!row.paidAt) {
      return '⏳️ Awaiting Payment'
    }
    if (!row.fulfilledAt) {
      return '️⌛️ Awaiting Fulfillment'
    }
    return '✔️ Done (Auto)'
  }
};

function Table({
                 columns,
                 data,
                 fetchData,
                 loading,
                 pageCount: controlledPageCount,
               }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0}, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({pageIndex, pageSize})
  }, [fetchData, pageIndex, pageSize])

  const exportCSV = (e) => {
    e.preventDefault();
    request(`${API}/order/export-customer-csv`)
      .then(res => {
        console.log({res})
        download('lexoffice-customer-data.csv',res.data)
      })
      .catch(e => {
        console.error(e)
        alert('Error fetching orders')
      })
  }

  // Render the UI for your table
  return (
    <div className="content-column flex">
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <h1>All Orders</h1>
        <div>
          <h1>
            <a href="" onClick={exportCSV}>Lexoffice Customer CSV</a>
          </h1>
          {/*<h1>*/}
          {/*  <Link to="/orders/invoice">Create Invoice</Link>*/}
          {/*</h1>*/}
          <h1>
            <Link to="/orders/tally">Show Tally</Link>
          </h1>
        </div>
      </div>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} style={row.original.isIgnored ? {textDecoration: 'line-through'} : {}}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        <tr>
          {loading ? (
            // Use our custom loading state to show a loading indicator
            <td colSpan="10000">Loading...</td>
          ) : (
            <td colSpan="10000">
              Showing {page.length} of ~{controlledPageCount * pageSize}{' '}
              results
            </td>
          )}
        </tr>
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        {' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        {' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        {' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        {' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{width: '100px'}}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[5, 10, 20, 30, 40, 50, 500].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

function OrderTable() {
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: idCell
      },
      {
        Header: 'Submitted',
        accessor: 'createdAt',
        Cell: dateCell
      },
      {
        Header: 'Status',
        accessor: statusAccessor,
        id: 'status'
      },
      {
        Header: 'First Name',
        accessor: 'firstName'
      },
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Total',
        accessor: 'total',
        Cell: moneyCell
      },
      {
        Header: 'Tax',
        accessor: taxLabel,
        id: 'tax-label'
      },
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({pageSize, pageIndex}) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);

    request(`${API}/order/?pageIndex=${pageIndex}&pageSize=${pageSize}`)
      .then(res => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data.orders);
          setPageCount(Math.ceil(res.data.totalPageCount));
          setLoading(false)
        }
      })
      .catch(e => {
        console.error(e)
        alert('Error fetching orders')
      })

  }, []);

  return (
    <Table
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
    />
  )
}

export default OrderTable
