import React from 'react';
import request from 'axios';
import Dinero from 'dinero.js';
import {API} from "../../../constants";
import Input from "../../generic/Input";
import Toggle from "../../generic/Toggle";
import {cloneDeep} from 'lodash'

class OrderDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sendingBill: false,
      fulfillingOrder: false
    }
  }

  handleToggle = ({target}) => {
    this.setState({
      [target.name]: !this.state[target.name]
    })
  };

  handleChange = ({target}) => {
    this.setState({
      [target.name]: target.value
    })
  };

  handleSendBill = () => {
    this.setState({sendingBill: true})
    request
      .post(`${API}/order/send-bill/${this.state.id}`)
      .then(() => {
        this.setState({sendingBill: false})
      })
      .catch(e => {
        console.error(e)
        this.setState({sendingBill: false})
        alert('Error sending bill for order')
      })
  }

  handleFulfillOrder = () => {
    this.setState({fulfillingOrder: true})
    request
      .post(`${API}/order/fulfill-order/${this.state.id}`)
      .then(() => {
        this.setState({fulfillingOrder: false})
      })
      .catch(e => {
        console.error(e)
        this.setState({fulfillingOrder: false})
        alert('Error fulfilling order')
      })
  }

  handleSaveChanges = () => {
    const data = cloneDeep(this.state);
    this.setState({loading: true});
    delete data.loading;
    delete data.sendingBill;
    delete data.fulfillingOrder;
    request.patch(`${API}/order`, data)
      .then(() => {
        this.setState({loading: false})
      })
      .catch(e => {
        console.error(e);
        alert('Failed to update order data');
        this.setState({loading: false})
      })
  };

  componentDidMount() {
    request(`${API}/order/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          loading: false,
          ...res.data
        })
      })
      .catch(e => {
        console.error(e);
        this.setState({loading: false});
        alert("Failed to load order data")
      })
  }

  render() {
    const {id} = this.props.match.params;
    const {
      loading,
      sendingBill,
      fulfillingOrder,
      email,
      firstName,
      lastName,
      company,
      vatCode,
      vatArea,
      vatRate,
      vatAmount,
      discountRate,
      discountAmount,
      subTotal,
      total,
      licensee,
      address1,
      address2,
      postalCode,
      city,
      country,
      phone,
      fulfilledAt,
      paidAt,
      externalId,
      autoFulfillment,
      gateway,
      hasError,
      systemMessages,
      notes,
      billingData,
      isIgnored,
      manualReview,
      createdAt,
      updatedAt,
      CouponId,
      Cuts,
      Licenses,
      Coupon
    } = this.state;

    if (loading) {
      return 'Loading...'
    }

    return (<div className="content-column">
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <a href="/orders">← Back to overview</a>
        <button onClick={this.handleSaveChanges}>Save Changes</button>
      </div>
      <h1>ORDER {id}</h1>
      <div className="hr"/>
      <p>⚠️ On this page, you need to confirm all changes by hitting the 'Save Changes' button in the top right corner
        ⚠️</p>
      <div>CREATED: {new Date(createdAt).toLocaleString()}</div>
      <div>UPDATED: {new Date(updatedAt).toLocaleString()}</div>
      <h2>System / Flags</h2>
      <dl>
        {systemMessages && systemMessages.updates.map(i => <>
          <dt>{new Date(i.timestamp).toLocaleString()}</dt>
          <dd>{i.message}</dd>
        </>)}
      </dl>
      <Toggle onToggle={this.handleToggle} label="Manual Review" name="manualReview" active={manualReview}/>
      <Toggle onToggle={this.handleToggle} label="Has Error" name="hasError" active={hasError}/>
      <Toggle onToggle={this.handleToggle} label="Auto Fulfillment" name="autoFulfillment" active={autoFulfillment}/>
      <p>If this order did not end in a payment, set it to ignored, so that it will not be used in accounting.</p>
      <Toggle onToggle={this.handleToggle} label="Is Ignored" name="isIgnored" active={isIgnored}/>

      <h2>Notes</h2>
      <textarea name="notes" id="notes" rows="10" value={notes || ""} placeholder="Add notes to this order"
                onChange={this.handleChange}/>

      <h2>Customer Data</h2>
      <table className="editable">
        <tbody>
        <tr>
          <th>Licensee</th>
          <td><Input name="licensee" value={licensee} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>First Name</th>
          <td><Input name="firstName" value={firstName} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Last Name</th>
          <td><Input name="lastName" value={lastName} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Email</th>
          <td><Input name="email" value={email} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Phone</th>
          <td><Input name="phone" value={phone} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Address Line 1</th>
          <td><Input name="address1" value={address1} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Address Line 2</th>
          <td><Input name="address2" value={address2} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Postal Code</th>
          <td><Input name="postalCode" value={postalCode} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>City</th>
          <td><Input name="city" value={city} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Country</th>
          <td><Input name="country" value={country} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Company</th>
          <td><Input name="company" value={company} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>VAT Number</th>
          <td><Input name="vatCode" value={vatCode} onChange={this.handleChange}/></td>
        </tr>
        </tbody>
      </table>
      <h2>Linked Cuts</h2>
      {
        Cuts && Cuts.length > 0 &&
        <table>
          <thead>
          <tr>
            <th>Typeface</th>
            <th>Cut</th>
          </tr>
          </thead>
          <tbody>
          {
            Cuts.map(cut => (<tr>
              <td>{cut.Typeface.name}</td>
              <td>{cut.name}</td>
            </tr>))
          }
          </tbody>
        </table>
      }
      <br/>
      <button
        onClick={this.handleFulfillOrder}
        disabled={fulfillingOrder}
      >
        {fulfillingOrder ? 'Fulfilling Order...' : 'Fulfill Order'}
      </button>
      <h2>Linked Licenses</h2>
      {
        Licenses && Licenses.length > 0 &&
        <table>
          <thead>
          <tr>
            <th>Platform</th>
            <th>License</th>
          </tr>
          </thead>
          <tbody>
          {
            Licenses.map(license => (<tr>
              <td>{license.Platform.label}</td>
              <td>{license.label}</td>
            </tr>))
          }
          </tbody>
        </table>
      }
      <h2>Billing Items</h2>
      {billingData && billingData.items.map((i, idx) => <div key={idx} className="billing-item">
        <div>{i.label}</div>
        <div>{Dinero({amount: i.price, currency: 'EUR'}).toFormat()}</div>
      </div>)}
      <button
        onClick={this.handleSendBill}
        disabled={sendingBill}
      >
        {sendingBill ? 'Sending Bill...' : 'Send Bill'}
      </button>
      <h2>Discounts</h2>
      <table>
        <tbody>
        <tr>
          <th>Discount Rate</th>
          <td>{discountRate}</td>
        </tr>
        <tr>
          <th>Discount Amount</th>
          <td>{discountAmount ? Dinero({amount: discountAmount, currency: 'EUR'}).toFormat() : 'None'}</td>
        </tr>
        </tbody>
      </table>
      <h2>Tax</h2>
      <table>
        <tbody>
        <tr>
          <th>VAT Area</th>
          <td>{vatArea}</td>
        </tr>
        <tr>
          <th>VAT Code</th>
          <td>{vatCode}</td>
        </tr>
        <tr>
          <th>VAT Rate</th>
          <td>{vatRate}</td>
        </tr>
        <tr>
          <th>VAT Amount</th>
          <td>{vatAmount ? Dinero({amount: vatAmount, currency: 'EUR'}).toFormat() : 'None'}</td>
        </tr>
        </tbody>
      </table>
      <h2>Coupon</h2>
      {Coupon ?
        <table>
          <tbody>
          <tr>
            <th>Label</th>
            <td>{Coupon.label}</td>
          </tr>
          <tr>
            <th>Discount Rate</th>
            <td>{Coupon.discountRate}</td>
          </tr>
          </tbody>
        </table> :
        <div>No coupon used on this order</div>
      }
      <h2>Final Price</h2>
      <div className="billing-item">
        <div>Subtotal</div>
        <div>{subTotal ? Dinero({amount: subTotal, currency: 'EUR'}).toFormat() : 'None'}</div>
      </div>
      <div className="billing-item">
        <div>Total</div>
        <div>{total ? Dinero({amount: total, currency: 'EUR'}).toFormat() : 'None'}</div>
      </div>
      <h2>Payment</h2>
      {paidAt ?
        <div>
          <div>
            The order has been paid on {new Date(paidAt).toLocaleString()}
          </div>
          <br/>
          <button onClick={() => {
            this.setState({paidAt: null})
          }}>
            Set order to unpaid
          </button>
        </div> :
        <div>
          <div>
            The order has not been paid yet
          </div>
          <br/>
          <button onClick={() => {
            this.setState({paidAt: new Date().toISOString()})
          }}>
            Set order to paid
          </button>
        </div>
      }
      <br/>
      <div>Gateway: {gateway}</div>
      <div>External ID: {externalId}</div>
      <h2>Fulfillment</h2>
      {fulfilledAt ?
        <div>
          <div>
            The order has been fulfilled on {new Date(fulfilledAt).toLocaleString()}
          </div>
          <br/>
          <button onClick={() => {
            this.setState({fulfilledAt: null})
          }}>
            Set order to unfulfilled
          </button>
        </div> :
        <div>
          <div>
            The order has not been fulfilled yet
          </div>
          <br/>
          <button onClick={() => {
            this.setState({fulfilledAt: new Date().toISOString()})
          }}>
            Set order to fulfilled
          </button>
        </div>
      }
    </div>)
  }
}

OrderDetails.propTypes = {}

export default OrderDetails;
