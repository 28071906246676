import React from 'react';
import axios from 'axios';
import {API} from "../../../constants";

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      loading: false,
    }
  }

  handleNameChange = ({target}) => {
    this.setState({name: target.value})
  };

  handlePasswordChange = ({target}) => {
    this.setState({password: target.value})
  };

  handleSubmit = () => {
    const {name, password} = this.state;
    this.setState({loading: true})
    axios.post(`${API}/user/login`, {
      name, password
    })
      .then(res => {
        const {token} = res.data;
        window.sessionStorage.setItem('jwt', token);
        const entryPath = window.sessionStorage.getItem('entry_path')
        this.props.history.replace(entryPath ? entryPath : '/')
      })
      .catch(e => {
        console.error(e)
        alert('Login failed!');
        this.setState({loading: false})
      })
  };

  render() {
    const {name, password, loading} = this.state;
    return (
      <div className="login-container">
        LOG INTO FLOUZ
        <input
          placeholder="Username"
          value={name}
          disabled={loading}
          onChange={this.handleNameChange}
        />
        <input
          placeholder="Password"
          type="password"
          value={password}
          disabled={loading}
          onChange={this.handlePasswordChange}
        />
        <button
          disabled={loading}
          onClick={this.handleSubmit}
        >{loading ? 'Please wait...' : 'Log me in!'}
        </button>
      </div>
    )
  }
}


export default Login;
