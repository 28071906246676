import React from 'react';
import Body from "../../generic/Body";
import {API, TAB_COLOR_1} from "../../../constants";
import Content from "../../generic/Content";
import carImage from "../../../assets/car.png";
import moment from "moment";
import request from "axios";
import Dinero from "dinero.js";

class Statistics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.fetchData()
  }


  fetchData = () => {

    request.get(`${API}/typeface/revenue-info`)
      .then(res => {
        console.log(res.data);
        const items = res.data.map(i => {
          let typefaceSales = 0;
          let typefaceTotal = 0;
          const countedTfOrders = [];
          i.Cuts.forEach(cut => {
            let cutTotal = 0;
            const countedCutOrders = [];
            typefaceSales += cut.Orders.length;
            cut.Orders.forEach(order => {
              if(!countedTfOrders.includes(order.id)){
                typefaceTotal += order.subTotal;
                countedTfOrders.push(order.id);
              }
              if(!countedCutOrders.includes(order.id)){
                cutTotal += order.subTotal;
                countedCutOrders.push(order.id);
              }
            })
            cut.cutTotal = cutTotal;
            cut.cutSales = cut.Orders.length
          });
          return ({
            ...i,
            typefaceSales,
            typefaceTotal
          })
        })
        console.log({items});
        this.setState({
          data: items
        })
      })
      .catch(e => {
        console.error(e)
        alert('Error fetching stats')
      })
  }

  render() {
    return (
      <Body color={TAB_COLOR_1}>
        <Content>
          <table>
            <tr>
              <th>Name</th>
              <th>Sales</th>
              <th>Total</th>
            </tr>
            {this.state.data.map(item => (
              <tr>
                <td>{item.name}</td>
                <td>{item.typefaceSales}</td>
                <td>{Dinero({amount: item.typefaceTotal, currency: 'EUR'}).toFormat()}</td>
              </tr>
            ))
            }
          </table>

          {this.state.data.map(item => (
            <div>
              <div>{item.name}</div>
              <table>
                {item.Cuts.map(item => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.cutSales}</td>
                    <td>{Dinero({amount: item.cutTotal, currency: 'EUR'}).toFormat()}</td>
                  </tr>
                ))
                }
              </table>
            </div>
          ))
          }
        </Content>
      </Body>
    )
  }
}

export default Statistics;
