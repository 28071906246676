export const TAB_COLOR_1 = "#dc4358";
export const TAB_COLOR_2 = "#ffce7d";
export const TAB_COLOR_3 = "#4e759e";
export const TAB_COLOR_4 = "#eb6740";
export const TAB_COLOR_5 = "#7eb28e";

export const API = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : '/api';

export const formatVat = rate => Math.floor(rate * 100)

