import React from 'react';
import Input from "../../generic/Input";
import PriceInput from "../../generic/PriceInput";
import Dinero from "dinero.js";
import request from 'axios';
import {API} from "../../../constants";

class InvoiceCreator extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editorsNote: '',
      email: '',
      firstName: '',
      lastName: '',
      company: '',
      vatCode: '',
      vatArea: '',
      vatRate: 0.05,
      vatAmount: 0,
      discountRate: 0,
      discountAmount: 0,
      address1: '',
      address2: '',
      postalCode: '',
      city: '',
      country: '',
      region: '',
      phone: '',
      billingData: {
        items: []
      },
      subTotal: 0,
      total: 0,
      priceLoading: false,
      sending: false
    }
  }

  handleSendInvoice = () => {
    this.setState({sending: true})
    request.post(`${API}/order/invoice`, this.state)
      .then(res => {
        alert(`Invoice sent with id '${res.data.id}'`)
        this.props.history.push('/orders')
      })
      .catch(e => {
        console.error(e)
        this.setState({sending: false})
        alert('Error sending invoice')
      })
  }

  handleChange = ({target}) => {
    this.setState({
      [target.name]: target.value
    }, this.updatePrice)
  };

  addBillingItem = () => {
    this.setState({
      billingData: {
        items: [
          ...this.state.billingData.items,
          {
            label: '',
            price: 0
          }
        ]
      }
    })
  }

  updatePrice = () => {
    let subTotal = new Dinero({amount: 0, currency: 'EUR'})

    this.state.billingData.items.forEach(i => {
      const itemPrice = new Dinero({amount: i.price, currency: 'EUR'})
      subTotal = subTotal.add(itemPrice);
    })

    // Calculate discount
    const discountAmount = subTotal.multiply(this.state.discountRate)
    subTotal = subTotal.subtract(discountAmount);

    // Calculate total after tax
    const taxAmount = subTotal.multiply(this.state.vatRate);
    const total = subTotal.add(taxAmount);

    this.setState({
      subTotal: subTotal.getAmount(),
      discountAmount: discountAmount.getAmount(),
      total: total.getAmount(),
      vatAmount: taxAmount.getAmount(),
      priceLoading: false
    })

  }

  removeBillingItem = (idx) => {
    const items = this.state.billingData.items.slice();
    items.splice(idx, 1);
    this.setState({
      billingData: {
        items
      }
    })
  }

  updateItemLabel = (idx, label) => {
    const items = this.state.billingData.items.slice();
    items[idx].label = label;
    this.setState({
      billingData: {
        items
      }
    })
  }

  updateItemPrice = (idx, price) => {
    const items = this.state.billingData.items.slice();
    items[idx].price = price;
    this.setState({
      billingData: {
        items
      },
      priceLoading: true
    }, this.updatePrice)
  }

  render() {

    const {
      email,
      firstName,
      lastName,
      company,
      vatCode,
      vatArea,
      discountRate,
      discountAmount,
      sending,
      vatRate,
      vatAmount,
      subTotal,
      total,
      billingData,
      address1,
      address2,
      postalCode,
      city,
      country,
      priceLoading,
      phone
    } = this.state;

    if(sending){
      return <div className="content-column">
        <h1>SENDING INVOICE...</h1>
        <p>Please wait a moment.</p>
      </div>
    }

    return (<div className="content-column">
      <h1>CREATE INVOICE</h1>
      <p>
        After creating your invoice and hitting send, the customer with the given email address will receive a PDF
        invoice via email.
      </p>
      <h2>Customer Data</h2>
      <table className="editable">
        <tbody>
        <tr>
          <th>First Name</th>
          <td><Input name="firstName" value={firstName} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Last Name</th>
          <td><Input name="lastName" value={lastName} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Email</th>
          <td><Input name="email" value={email} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Phone</th>
          <td><Input name="phone" value={phone} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Address Line 1</th>
          <td><Input name="address1" value={address1} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Address Line 2</th>
          <td><Input name="address2" value={address2} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Postal Code</th>
          <td><Input name="postalCode" value={postalCode} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>City</th>
          <td><Input name="city" value={city} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Country Code (e.g. 'DE')</th>
          <td><Input name="country" value={country} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>Company</th>
          <td><Input name="company" value={company} onChange={this.handleChange}/></td>
        </tr>
        <tr>
          <th>VAT Number</th>
          <td><Input name="vatCode" value={vatCode} onChange={this.handleChange}/></td>
        </tr>
        </tbody>
      </table>
      <h2>Billing Items</h2>
      <table className="editable">
        <tbody>
        <tr>
          <th>LABEL</th>
          <th>PRICE</th>
        </tr>
        {billingData.items.map((i, idx) => (
          <tr key={idx}>
            <td>
              <Input
                value={i.label}
                placeholder="Item label..."
                onChange={({target}) => {
                  this.updateItemLabel(idx, target.value)
                }}/>
            </td>
            <td>
              <PriceInput onChange={(value) => {
                this.updateItemPrice(idx, value)
              }} value={i.price}/>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <br/>
      <button onClick={this.addBillingItem}>Add Billing Item</button>

      <h2>Personal Note</h2>
      <p>
        Here you can add an optional note to the customer that will appear in the email body.
        The note will appear after the following generated section:
      </p>
      <i>
Dear firstName,
<br/><br/>
Attached you will find your invoice from Gruppo Due.
<br/><br/>
If you have any questions or concerns regarding your order, please do not hesitate to contact us by responding directly to this email.
Your full order reference is 'id'.
<br/><br/>
A note from our team:
      </i>
      <br/><br/>
      <textarea name="editorsNote" id="" cols="30" rows="10" onChange={this.handleChange}/>

      {priceLoading ?
        <>
          <h2>Price is loading...</h2>
          Please wait a moment
        </> :
        <>
          <h2>Discount</h2>
          <p>Optional discount, e.g. 0.15 for 15% off.</p>
          <table className="editable">
            <tbody>
            <tr>
              <th>Discount Rate</th>
              <td>
                <
                  Input onChange={this.handleChange}
                        value={discountRate}
                        type="number"
                        name="discountRate"/>
              </td>
            </tr>
            <tr>
              <th>Discount Amount</th>
              <td>{discountAmount ? Dinero({amount: discountAmount, currency: 'EUR'}).toFormat() : 'None'}</td>
            </tr>
            </tbody>
          </table>

          <h2>TAX</h2>
          <p>!! You need to manually input the VAT rate based on customer location, e.g. 0.07 for 7% !!</p>
          <table className="editable">
            <tbody>
            <tr>
              <th>VAT Rate</th>
              <td>
                <
                  Input onChange={this.handleChange}
                        value={vatRate}
                        type="number"
                        name="vatRate"/>
              </td>
            </tr>
            <tr>
              <th>VAT Amount</th>
              <td>{vatAmount ? Dinero({amount: vatAmount, currency: 'EUR'}).toFormat() : 'None'}</td>
            </tr>
            </tbody>
          </table>

          <h2>PRICE</h2>
          <div className="billing-item">
            <div>Subtotal</div>
            <div>{subTotal ? Dinero({amount: subTotal, currency: 'EUR'}).toFormat() : 'None'}</div>
          </div>
          <div className="billing-item">
            <div>Total</div>
            <div>{total ? Dinero({amount: total, currency: 'EUR'}).toFormat() : 'None'}</div>
          </div>

          {firstName && lastName &&  address1 &&city && country &&postalCode && total && email ?
            <button onClick={this.handleSendInvoice}>Send Invoice</button>
            :<div>Some information is still missing, check the forms above.</div>
          }
        </>
      }


    </div>)
  }
}

InvoiceCreator.propTypes = {}

export default InvoiceCreator;
