import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import './App.css';
import NavBar from "./components/generic/NavBar";
import NavTitle from "./components/generic/NavTitle";
import TabItem from "./components/generic/TabItem";
import Overview from "./components/pages/overview/Overview";
import Inventory from "./components/pages/inventory/Inventory";
import Orders from "./components/pages/orders/Orders";
import Config from "./components/pages/config/Config";
import {TAB_COLOR_1, TAB_COLOR_2, TAB_COLOR_3, TAB_COLOR_4} from "./constants";
import Login from "./components/pages/login/Login";
import Statistics from "./components/pages/inventory/Statistics";


function App() {
  return (
    <Router>
      <div className="App visible">
        <Switch>
          <Route exact path="/" component={Overview}/>
          <Route exact path="/inventory" component={Inventory}/>
          <Route path="/orders" component={Orders}/>
          <Route path="/statistics" component={Statistics}/>
          <Route path="/config" component={Config}/>
          <Route exact path="/login" component={Login} />
        </Switch>
        <NavBar>
          <NavTitle>g2-flouz</NavTitle>
          <TabItem link="/" color={TAB_COLOR_1}>Overview</TabItem>
          <TabItem link="/inventory" color={TAB_COLOR_2}>Inventory</TabItem>
          <TabItem link="/orders" color={TAB_COLOR_3}>Orders</TabItem>
          <TabItem link="/config" color={TAB_COLOR_4}>Config</TabItem>
        </NavBar>
      </div>
    </Router>
  );
}

export default App;
