import React from 'react';
import Body from "../../generic/Body";
import {API, TAB_COLOR_2} from "../../../constants";
import Content from "../../generic/Content";
import Typeface from "./Typeface";
import request from 'axios';
import {Link} from "react-router-dom";

class Inventory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      typefaces: [],
      loadingTypefaces: true,
      addingTypeface: false
    }
  }

  componentDidMount() {
    this.getTypefaces()
  }

  getTypefaces = () => {
    request(`${API}/typeface`)
      .then(res => {
        this.setState({typefaces: res.data, loadingTypefaces: false})
      })
      .catch(e => {
        console.error(e);
        alert('Failed to load typefaces!');
      })
  };

  handleAddTypeface = () => {
    this.setState({addingTypeface: true});
    request.post(`${API}/typeface`, {name: ''})
      .then(res => {
        this.setState({
          typefaces: [...this.state.typefaces, res.data],
          addingTypeface: false
        })
      })
  };

  handleTypefaceDelete = id => {
    this.setState({
      typefaces: this.state.typefaces.filter(t => t.id !== id)
    })
  };

  render() {
    const {typefaces, loadingTypefaces, addingTypeface} = this.state;
    return (
      <Body color={TAB_COLOR_2}>

        <Content>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <h1>Typefaces</h1>
            <div>
              <h1>
                <Link to="/statistics">Show Stats</Link>
              </h1>
            </div>
          </div>
          <div className="hr"/>
          {
            loadingTypefaces ? <h2>Loading...</h2> :
              <div className="typefaces-container">
                {
                  typefaces.map(typeface => (<Typeface onDelete={this.handleTypefaceDelete} key={typeface.id} data={typeface}/>))
                }
                {typefaces.length > 0 && <div className="hr"/>}

                <div className="button-container">
                  <button
                    disabled={addingTypeface}
                    className="medium"
                    onClick={this.handleAddTypeface}
                  >
                    {addingTypeface ? 'Adding Typeface...' : 'Add Typeface'}
                  </button>
                </div>
              </div>
          }
          <h1>Items</h1>
          <div className="hr"/>
          <div className="button-container">
            <button className="medium" onClick={()=>{alert('No! 🤠')}}>Add Item</button>
          </div>
        </Content>

      </Body>
    )
  }
}

export default Inventory;
