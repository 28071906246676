import React from 'react';
import {NavLink, Route, Switch, Redirect} from "react-router-dom";
import Body from "../../generic/Body";
import {TAB_COLOR_4} from "../../../constants";
import Content from "../../generic/Content";
import PlatformEditor from "./PlatformEditor";
import CouponManager from "./CouponManager";

class Config extends React.Component {

  render() {
    return (
      <Body color={TAB_COLOR_4}>
        <Content>
          <NavLink className="config-link" activeClassName="config-link-active" to="/config/licenses">LICENSES</NavLink>
          <NavLink className="config-link" activeClassName="config-link-active" to="/config/coupons">COUPONS</NavLink>
          <NavLink className="config-link" activeClassName="config-link-active" to="/config/mail">MAIL</NavLink>

          <Switch>
            <Route exact path="/config/licenses" component={PlatformEditor}/>
            <Route exact path="/config/coupons" component={CouponManager}/>

            <Redirect to="/config/licenses"/>
          </Switch>
        </Content>
      </Body>
    )
  }
}

export default Config;
