import React from 'react';
import request from "axios";
import {API} from "../../../constants";
import CouponEditor from "./CouponEditor";

class CouponManager extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      loadingCoupons: true,
      addingCoupon: false
    }
  }

  componentDidMount() {
    this.getCoupons()
  }

  getCoupons = () => {
    request(`${API}/coupon`)
      .then(res => {
        this.setState({coupons: res.data, loadingCoupons: false})
      })
      .catch(e => {
        console.error(e);
        alert('Failed to load coupons!');
      })
  };

  handleAddCoupon = () => {
    this.setState({addingCoupon: true});
    const dt = new Date();
    dt.setMonth(dt.getMonth() + 2);
    request.post(`${API}/coupon`, {
      code: '',
      label: '',
      discountRate: 0.0,
      validFrom: new Date().toISOString(),
      validTo: dt.toISOString(),
      uses: 1,
    })
      .then(res => {
        this.setState({
          coupons: [...this.state.coupons, res.data],
          addingCoupon: false
        })
      })
      .catch(e => {
        console.error(e);
        alert('Error adding coupon.')
      })
  };

  handleDeleteCoupon = () => {
    this.getCoupons();
  };

  render() {
    const {coupons, loadingCoupons, addingCoupon} = this.state;
    if (loadingCoupons) {
      return (<h2>Loading coupons...</h2>)
    }
    return (
      <div>
        <br/>
        {
          coupons.map(data => (
            <CouponEditor key={data.id} data={data} onDelete={this.handleDeleteCoupon}/>
          ))
        }
        <button
          className="medium"
          disabled={addingCoupon}
          onClick={this.handleAddCoupon}>{addingCoupon ? "Adding coupon..." : "Add Coupon"}
        </button>
      </div>
    )
  }
}

CouponManager.propTypes = {};

export default CouponManager;
