import React from 'react';
import PropTypes from 'prop-types';

const Toggle = ({active, label, inputId, onToggle, name}) => {
  return (
    <div className="toggle">
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={active}
        onChange={onToggle}
      />
      <label htmlFor={name}>{label}</label>
    </div>);
};

Toggle.propTypes = {
  active: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string
};

Toggle.defaultProps = {
  active: false,
  name: "scales"
};

export default Toggle
