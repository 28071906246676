import React from 'react';
import PropTypes from 'prop-types'
import Cut from "../inventory/Cut";
import Input from "../../generic/Input";
import request from "axios";
import {API} from "../../../constants";
import {debounce} from "lodash";

class LicenseEditor extends React.Component {

  constructor(props) {
    super(props);
    const {id, label, index, factor, createdAt, updatedAt} = this.props.data;
    this.state = {
      id, label, index, factor, createdAt, updatedAt,
      pending: false,
    }
  }

  handleIndexChange = ({target}) => {
    this.setState({index: target.value, pending: true});
    this.persist();
  };

  handleLabelChange = ({target}) => {
    this.setState({label: target.value, pending: true});
    this.persist();
  };

  handleFactorChange = ({target}) => {
    this.setState({factor: target.value, pending: true});
    this.persist()
  };

  handleDelete = () => {
    if (window.confirm(`Delete license "${this.state.label}"?`)) {
      request.delete(`${API}/license/${this.props.data.id}`)
        .then(() => {
          this.props.onDelete(this.props.data.id)
        })
        .catch(e => {
          console.error(e);
          alert("Failed to delete license")
        })
    }
  };

  persistNow = () => {
    if (!Number(this.state.factor)) return;
    const data = {
      id: this.props.data.id,
      label: this.state.label,
      factor: this.state.factor,
      index: this.state.index
    };
    request.patch(`${API}/license`, data)
      .then(res => {
        this.setState({pending: false})
      })
      .catch(e => {
        console.error(e);
        alert("Error persisting license data.")
      })
  };

  persist = debounce(this.persistNow, 500);

  render() {
    const {pending, id, index, label, factor} = this.state;
    return (<div className="license-editor-container">
      <div className="license-editor-label">#</div>
      <Input
        onChange={this.handleIndexChange}
        placeholder={"Sorting index"}
        pending={pending}
        value={index}
        style={{color: '#565656'}}
      />
      <Input
        onChange={this.handleLabelChange}
        placeholder={"🖋 Enter a label for this license type"}
        pending={pending}
        value={label}
        style={{color: '#565656'}}
      />
      <div className="license-editor-label">✕</div>
      <Input
        onChange={this.handleFactorChange}
        placeholder={"🧮 Enter a factor"}
        pending={pending}
        value={factor}
        type="number"
        style={{color: '#565656'}}
      />
      <button
        onClick={this.handleDelete}
      >
        Delete
      </button>
    </div>)
  }
}

LicenseEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    factor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
  onDelete: PropTypes.func.isRequired
};

export default LicenseEditor;
