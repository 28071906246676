import React from 'react';
import PropTypes from 'prop-types'
import Cut from "../inventory/Cut";
import Input from "../../generic/Input";
import request from "axios";
import {API} from "../../../constants";
import {debounce} from "lodash";

class CouponEditor extends React.Component {

  constructor(props) {
    super(props);
    const {id, code, label, discountRate, validFrom, validTo, uses, createdAt, updatedAt} = this.props.data;
    this.state = {
      id,
      code,
      label,
      discountRate,
      validFrom: new Date(validFrom).toISOString().split('T')[0],
      validTo: new Date(validTo).toISOString().split('T')[0],
      uses,
      pending: false,
    }
  }

  handleChange = ({target}) => {
    this.setState({[target.name]: target.value, pending: true});
    this.persist();
  };

  handleDelete = () => {
    if (window.confirm(`Delete coupon ${this.state.code} ${this.state.label}?`)) {
      request.delete(`${API}/coupon/${this.props.data.id}`)
        .then(() => {
          this.props.onDelete(this.props.data.id)
        })
        .catch(e => {
          console.error(e);
          alert("Failed to delete license")
        })
    }
  };

  handleSuspend = () => {
    if (window.confirm(`Set uses to zero for coupon ${this.state.code} ${this.state.label}?`)) {
      this.setState({uses: 0, pending: true}, () => {
        this.persistNow();
      })
    }
  };

  persistNow = () => {
    if(isNaN(new Date(this.state.validFrom).getTime())) return;
    if(isNaN(new Date(this.state.validTo).getTime())) return;
    if (!Number(this.state.discountRate)) return;
    request.patch(`${API}/coupon`, this.state)
      .then(res => {
        this.setState({pending: false})
      })
      .catch(e => {
        console.error(e);
        alert("Error persisting license data.")
      })
  };

  persist = debounce(this.persistNow, 500);

  render() {
    const {code, label, discountRate, validFrom, validTo, uses, pending} = this.state;
    return (
      <div style={pending ? {backgroundColor: 'rgba(255,255,255,0.1)'} : {}} className="cut-container">
        <label>
          Coupon Code:
          <input
            placeholder="e.g. LAUNCH15OFF"
            className="seamless-input"
            type="text"
            name="code"
            value={code}
            style={{
              textTransform: 'uppercase'
            }}
            onChange={this.handleChange}
          />
        </label>
        <label>
          Display Label:
          <input
            placeholder="e.g. 15% off on your purchase"
            className="seamless-input"
            type="text"
            name="label"
            value={label}
            onChange={this.handleChange}
          />
        </label>
        <label>
          Discount Rate (e.g. 0.15 for 15%):
          <input
            placeholder="e.g. 0.15 for 15%"
            className="seamless-input"
            type="number"
            name="discountRate"
            value={discountRate}
            onChange={this.handleChange}
          />
        </label>
        <label>
          Uses:
          <input
            placeholder="This counter will decrease with each use"
            className="seamless-input"
            type="number"
            name="uses"
            value={uses}
            onChange={this.handleChange}
          />
        </label>
        <label>
          Valid From:
          <input
            className="seamless-input"
            type="date"
            name="validFrom"
            value={validFrom}
            onChange={this.handleChange}
          />
        </label>
        <label>
          Valid Until:
          <input
            className="seamless-input"
            type="date"
            name="validTo"
            value={validTo}
            onChange={this.handleChange}
          />
        </label>
        <div className="button-container">
          <button
            className="medium"
            onClick={this.handleDelete}
          >
            Delete
          </button>
          <button
            className="medium"
            onClick={this.handleSuspend}
          >
            Suspend
          </button>
        </div>
      </div>
    )
  }
}

CouponEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string,
    label: PropTypes.string,
    discountRate: PropTypes.number.isRequired,
    validFrom: PropTypes.string.isRequired,
    validTo: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
  onDelete: PropTypes.func.isRequired
};

export default CouponEditor;
