import React from 'react';

class NavBar extends React.Component {

  render() {
    return (<div className="nav-bar">{this.props.children}</div>)
  }
}

export default NavBar;
