import React from 'react';
import request from 'axios';
import PropTypes from 'prop-types'
import Input from "../../generic/Input";
import {debounce} from 'lodash';
import {API} from "../../../constants";
import Cut from "./Cut";
import PriceInput from "../../generic/PriceInput";

class Typeface extends React.Component {

  constructor(props) {
    super(props);
    const {name, notes, price, createdAt, updatedAt, Cuts} = this.props.data;
    this.state = {
      name,
      price,
      notes,
      createdAt,
      updatedAt,
      addingCut: false,
      cuts: Cuts || [],
      pending: false,
    }
  }

  handleAddCut = () => {
    this.setState({addingCut: true});
    request.post(`${API}/cut`, {
      name: '',
      typefaceId: this.props.data.id,
    })
      .then(res => {
        this.setState({
          cuts: [...this.state.cuts, res.data],
          addingCut: false
        })
      })
      .catch(e => {
        console.error(e)
        alert('Error adding cut to typeface.')
      })
  };

  handleNameChange = ({target}) => {
    this.setState({name: target.value.trim(), pending: true});
    this.persist();
  };

  handleNotesChange = ({target}) => {
    this.setState({notes: target.value, pending: true});
    this.persist();
  };

  handleDelete = () => {
    if (window.confirm(`Delete typeface "${this.state.name}"?`)) {
      request.delete(`${API}/typeface/${this.props.data.id}`)
        .then(() => {
          this.props.onDelete(this.props.data.id)
        })
        .catch(e => {
          console.error(e);
          alert("Failed to delete typeface")
        })
    }
  };

  handlePriceChange = (value) => {
    this.setState({price: value, pending: true});
    this.persist();
  };

  handleCutDelete = id => {
    this.setState({
      cuts: this.state.cuts.filter(c => c.id !== id)
    })
  };

  persist = debounce(() => {
    request.patch(`${API}/typeface`, {
      id: this.props.data.id,
      name: this.state.name,
      notes: this.state.notes,
      price: this.state.price
    })
      .then(res => {
        this.setState({pending: false})
      })
      .catch(e => {
        console.error(e);
        alert("Error persisting typeface data.")
      })
  }, 500);

  render() {
    const {name, notes, pending, cuts, price, addingCut, createdAt, updatedAd} = this.state;
    return (
      <div style={{marginBottom: '1rem', width: "calc(100% - 2rem)"}}>
        <Input
          onChange={this.handleNameChange}
          placeholder="Untitled Typeface"
          pending={pending}
          value={name}
        />
        <Input
          onChange={this.handleNotesChange}
          placeholder={"📝 Notes"}
          pending={pending}
          value={notes}
          style={{color: '#565656'}}
        />
        {/*<PriceInput*/}
        {/*  onChange={this.handlePriceChange}*/}
        {/*  value={price}*/}
        {/*  pending={pending}*/}
        {/*/>*/}
        {cuts.map(cut => (<Cut onDelete={this.handleCutDelete} key={cut.id} data={cut}/>))}
        <div className="button-container">
          <button
            onClick={this.handleAddCut}
            disabled={addingCut}
            className="medium"
          >
            {addingCut ? 'Adding Cut..' : 'Add Cut'}
          </button>
          <button onClick={this.handleDelete} className="medium">Remove Typeface</button>
        </div>
      </div>
    )
  }
}

Typeface.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired
  }),
  onDelete: PropTypes.func.isRequired
};

export default Typeface;
