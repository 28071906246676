import React from 'react';
import PropTypes from 'prop-types'
import request from 'axios';
import Dropzone from "react-dropzone";
import nanoid from "nanoid";
import Input from "../../generic/Input";
import {debounce} from 'lodash';
import {API} from "../../../constants";

class Asset extends React.Component {

  constructor(props) {
    super(props);
    const {name, contentDelivery, createdAt, updatedAt} = this.props.data;
    this.state = {
      name,
      createdAt,
      updatedAt,
      contentDelivery,
      pending: false,
      updating: null
    }
  }

  handleNameChange = ({target}) => {
    this.setState({name: target.value, pending: true});
    this.persist();
  };

  handleContentDeliveryToggle = () => {
    this.setState({
      contentDelivery: !this.state.contentDelivery
    })
    this.persist()
  };

  handleDelete = () => {
    if (window.confirm(`Delete asset "${this.state.name}"?`)) {
      request.delete(`${API}/asset/${this.props.data.id}`)
        .then(() => {
          this.props.onDelete(this.props.data.id)
        })
        .catch(e => {
          console.error(e);
          alert("Failed to delete asset")
        })
    }
  };

  handleFileSubmit = (files) => {
    const file = files[0];
    if (!file) return;
    this.setState({
      updating: 0
    });
    const formData = new FormData();
    formData.append('asset', file);
    request.patch(`${API}/asset/update/${this.props.data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progress => {
        this.setState({
          updating: Math.floor((progress.loaded / progress.total) * 100)
        })
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          updating: null
        })
      })
      .catch(e => {
        this.setState({
          updating: null
        });
        console.error(e);
        alert('Asset update error')
      })
  };

  persist = debounce(() => {
    request.patch(`${API}/asset`, {
      id: this.props.data.id,
      name: this.state.name,
      contentDelivery: this.state.contentDelivery
    })
      .then(res => {
        this.setState({pending: false})
      })
      .catch(e => {
        console.error(e);
        alert("Error persisting cut data.")
      })
  }, 500);

  render() {
    const {name, pending, updating, contentDelivery, createdAt, updatedAd} = this.state;
    if (updating !== null) {
      return <div className="asset-container">
        <div className="asset-name">
          <input
            className="flex-input"
            value={`${updating}% Updating ${name}...`}
            readOnly
          />
        </div>
        <div className="asset-actions">
          <div title="Pending asset...">🚧</div>
        </div>
      </div>
    }
    return (
      <Dropzone onDrop={this.handleFileSubmit}>
        {({getRootProps, isDragActive}) => (
          <div {...getRootProps()}>
            {
              isDragActive ?
                <div className="asset-container drag-active">
                  <div className="asset-name">
                    <input
                      className="flex-input"
                      value={name}
                      readOnly
                    />
                  </div>
                  <div>
                    <div className="asset-action emoji-button" title="Replace asset">🏗</div>
                  </div>
                </div> :
                <div className="asset-container">
                  <div className="asset-name">
                    <Input
                      onChange={this.handleNameChange}
                      placeholder="Untitled Asset"
                      pending={pending}
                      value={name}
                    />
                  </div>
                  <div className="asset-actions">
                    {
                      contentDelivery ?
                        <div
                          onClick={this.handleContentDeliveryToggle}
                          className="asset-action emoji-button"
                          title="Content delivery: ON"
                        >🌍
                        </div> :
                        <div
                          onClick={this.handleContentDeliveryToggle}
                          className="asset-action emoji-button"
                          title="Content delivery: OFF"
                        >🔒
                        </div>
                    }
                    <Dropzone onDrop={this.handleFileSubmit}>
                      {({getRootProps, getInputProps, isDragActive}) => (
                        <div>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="asset-action emoji-button" title="Replace Asset File">🧰</div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div onClick={this.handleDelete} className="asset-action emoji-button" title="Remove Asset">🗑</div>
                  </div>
                </div>
            }
          </div>
        )}
      </Dropzone>
    )
  }
}

Asset.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired
  }),
  onDelete: PropTypes.func.isRequired
};

export default Asset;
