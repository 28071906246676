import React from 'react';
import PropTypes from 'prop-types';
import Dinero from 'dinero.js';
import SettingsService from "../../services/SettingsService";

class PriceInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      current: this.parseValue(props.value),
      active: false
    }
  }

  parseValue = (value) => {
    const din = Dinero({amount: value, currency: SettingsService.currency});
    din.setLocale(SettingsService.locale);

    return din.toUnit();
  };

  getPrettyFromRaw = (value) => {
    const din = Dinero({amount: value, currency: SettingsService.currency});
    din.setLocale(SettingsService.locale);

    return din.toFormat()
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.parseValue(this.props.value)})
    }
  };

  handleChange = ({target}) => {
    this.setState({current: target.value})
  };

  handleApply = () => {
    const originalNumber = Number(this.state.current).toFixed(2);
    const rawNumber = this.state.current ? Number(String(originalNumber).replace('.', '')) : 0;
    console.log('raw ', rawNumber);
    this.props.onChange(rawNumber);
    this.setState({active: false})
  };

  activateInput = () => this.setState({active: true});

  render() {
    const {pending, style, value} = this.props;
    const {current, active} = this.state;
    return (
      <div className="price-input">
        {
          active ?
            <div>
              <input
                autoFocus
                min="0.00"
                step="0.01"
                type="number"
                value={current || ''}
                onChange={this.handleChange}
                onBlur={this.handleApply}
                className={`seamless-input ${pending ? 'pending' : ''}`}
                style={style}
              />
            </div>
            :
            <input
              type="text"
              readOnly
              className={`seamless-input ${pending ? 'pending' : ''}`}
              onClick={this.activateInput}
              value={this.getPrettyFromRaw(value)}
            />
        }

      </div>
    )
  }
}

PriceInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

PriceInput.defaultProps = {
  placeholder: ''
};

export default PriceInput;
