import React from 'react';
import Body from "../../generic/Body";
import {TAB_COLOR_3} from "../../../constants";
import OrderTable from "./OrderTable";
import OrderDetails from "./OrderDetails";

import { Route, Switch} from "react-router-dom";
import InvoiceCreator from "./InvoiceCreator";
import AccountingTally from "./AccountingTally";



class Orders extends React.Component {

  render() {
    return (
      <Body color={TAB_COLOR_3}>
        <Switch>
          <Route exact path="/orders" component={OrderTable}/>
          <Route exact path="/orders/invoice" component={InvoiceCreator}/>
          <Route exact path="/orders/tally" component={AccountingTally}/>
          <Route exact path="/orders/:id" component={OrderDetails}/>
        </Switch>
      </Body>
    )
  }
}

export default Orders;
