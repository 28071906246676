import React from 'react';

class Body extends React.Component {

  render() {
    const {children, color} = this.props;
    return (<div className="content-body" style={{backgroundColor: color}}>{children}</div>)
  }
}


export default Body;
