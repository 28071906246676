import React from 'react';
import request from "axios";
import {API} from "../../../constants";
import LicenseEditor from "./LicenseEditor";
import Content from "../../generic/Content";
import Input from "../../generic/Input";
import {debounce} from "lodash";

class PlatformEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      platforms: [],
      loadingLicenses: true,
      addingLicense: false,
      pending: false
    }
  }

  componentDidMount() {
    this.getLicenses()
  }

  getLicenses = () => {
    request(`${API}/license`)
      .then(res => {
        this.setState({platforms: res.data, loadingLicenses: false})
      })
      .catch(e => {
        console.error(e);
        alert('Failed to load licenses!');
      })
  };

  handleAddLicense = (platform, index) => {
    this.setState({addingLicense: true});
    request.post(`${API}/license`, {
      label: '',
      platformId: platform.id,
    })
      .then(res => {
        const updatedPlatforms = this.state.platforms.slice();
        updatedPlatforms[index].Licenses = [...updatedPlatforms[index].Licenses, res.data];
        this.setState({
          platforms: updatedPlatforms,
          addingLicense: false
        })
      })
      .catch(e => {
        console.error(e);
        alert('Error adding license to platform.')
      })
  };

  handleDeleteLicense = (platform, index, id) => {
    const updatedPlatforms = this.state.platforms.slice();
    updatedPlatforms[index].Licenses = updatedPlatforms[index].Licenses.filter(license => license.id !== id);
    this.setState({
      platforms: updatedPlatforms
    })
  };

  handleDescriptorChange = ({target}) => {
    console.log("Descriptor change")
    const updatedPlatforms = this.state.platforms.slice();
    updatedPlatforms[Number(target.name)].descriptor = target.value;
    this.setState({
      platforms: updatedPlatforms
    }, () => {
      this.persistPlatform(updatedPlatforms[Number(target.name)]);
    })
  }

  handleLabelChange = ({target}) => {
    console.log("Label change")
    const updatedPlatforms = this.state.platforms.slice();
    updatedPlatforms[Number(target.name)].label = target.value;
    this.setState({
      platforms: updatedPlatforms
    }, () => {
      this.persistPlatform(updatedPlatforms[Number(target.name)]);
    })
  }

  persistPlatform = (platform) => {
    const data = {
      id: platform.id,
      descriptor: platform.descriptor,
      label: platform.label
    };
    request.patch(`${API}/platform`, data)
      .then(res => {
        this.setState({pending: false})
      })
      .catch(e => {
        console.error(e);
        alert("Error updating platform data.")
      })
  };

  render() {
    const {loadingLicenses, platforms, addingLicense, pending} = this.state;
    if (loadingLicenses) {
      return (<h2>Loading licenses...</h2>)
    }
    return (
      <div>
        {platforms.map((platform, index) => <div key={platform.id}>
          <Input
            onChange={this.handleLabelChange}
            placeholder={"💻 Platform Label"}
            pending={pending}
            value={platform.label}
            name={index}
            style={{color: '#565656'}}
          />
          <Input
            onChange={this.handleDescriptorChange}
            placeholder={"💻 Platform Descriptor"}
            pending={pending}
            value={platform.descriptor}
            name={index}
            style={{color: '#565656'}}
          />
          {
            platform.Licenses.map(license => (<LicenseEditor key={license.id} onDelete={(id) => {
              this.handleDeleteLicense(platform, index, id)
            }
            } data={license}/>))
          }
          <button
            onClick={() => {
              this.handleAddLicense(platform, index)
            }}
            disabled={addingLicense}
            className="medium"
          >
            {addingLicense ? 'Adding License..' : 'Add License'}
          </button>
        </div>)}
      </div>
    )
  }
}

PlatformEditor.propTypes = {};

export default PlatformEditor;
