import React from 'react';
import request from 'axios';
import moment from 'moment';
import {API} from "../../../constants";
import {debounce} from "lodash";
import Input from "../../generic/Input";
import Dinero from "dinero.js";

class AccountingTally extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      year: moment().year(),
      month: `${moment().month()}`,
      loading: true,
      data: null,
      startDate: null,
      endDate: null
    }
  }

  componentDidMount() {
    this.fetchData()
  }


  fetchData = () => {
    const {year, month} = this.state;
    let startDate, endDate;

    if (month === 'Q1') {
      startDate = moment(`${year}`).quarter(1).startOf('quarter');
      endDate = moment(`${year}`).quarter(1).endOf('quarter');
    } else if (month === 'Q2') {
      startDate = moment(`${year}`).quarter(2).startOf('quarter');
      endDate = moment(`${year}`).quarter(2).endOf('quarter');
    } else if (month === 'Q3') {
      startDate = moment(`${year}`).quarter(3).startOf('quarter');
      endDate = moment(`${year}`).quarter(3).endOf('quarter');
    } else if (month === 'Q4') {
      startDate = moment(`${year}`).quarter(4).startOf('quarter');
      endDate = moment(`${year}`).quarter(4).endOf('quarter');
    } else if (month === 'all') {
      startDate = moment(`${year}`).startOf('year');
      endDate = moment(`${year}`).endOf('year')
    } else {
      startDate = moment(`${Number(month) + 1}-${year}`, 'M-YYYY').startOf('month')
      endDate = moment(`${Number(month) + 1}-${year}`, 'M-YYYY').endOf('month')
    }

    request.get(`${API}/order/tally?startDate=${encodeURIComponent(startDate.format())}&endDate=${encodeURIComponent(endDate.format())}`)
      .then(res => {
        this.setState({
          data: res.data,
          loading: false,
          startDate: startDate,
          endDate: endDate
        })
      })
      .catch(e => {
        console.error(e)
        alert('Error fetching tally')
        this.props.history.push('/orders')
      })
  }
  debouncedFetch = debounce(this.fetchData, 500);

  handleYearChange = ({target}) => {
    this.setState({year: target.value, loading: true}, this.debouncedFetch)
  }

  handleMonthChange = ({target}) => {
    this.setState({month: target.value, loading: true}, this.fetchData)
  }

  runSanityChecks = () => {
    const {data} = this.state;
    const issues = [];

    // Check that listed categories add up to all (subTotal)
    let overall = 0;
    Object.keys(data.vatCharged).forEach(key => {
      overall += data.vatCharged[key].subTotal;
    })
    overall += data.exemptExport.subTotal
    overall += data.exemptBusiness.subTotal

    if (data.all.subTotal !== overall) {
      issues.push('Categories do not match up to total.')
    }

    return issues;
  }

  render() {
    const {loading, data, year, month, startDate, endDate} = this.state;
    return (<div className="content-column">
        <h1>Accounting Tally</h1>

        <div style={{display: 'flex'}}>
          <Input type="number" onChange={this.handleYearChange} value={year}/>
          <select
            value={month}
            onChange={this.handleMonthChange}>
            {moment.months().map((month, idx) => <option key={month} value={idx}>{month}</option>)}
            <option value="all">-- Entire Year --</option>
            <option value="Q1">Q1</option>
            <option value="Q2">Q2</option>
            <option value="Q3">Q3</option>
            <option value="Q4">Q4</option>

          </select>
        </div>

        {loading ?
          <div>Loading...</div> :
          <div>
            {
              data && <>

                {this.runSanityChecks().map(issue => (<p>ISSUE: {issue}</p>))}


                {month === 'all' && <p>
                  Total income from non-German EU orders: {Dinero({
                  amount: data.anyVatChargedEU.total,
                  currency: 'EUR'
                }).toFormat()}
                </p>}

                {Object.keys(data.vatCharged).map(rate => (<div key={`VAT_${rate}`}>
                  <h2>Orders with {rate}% VAT charged</h2>
                  <p>Count: {data.vatCharged[rate].orderVolume}</p>
                  <div className="billing-item">
                    <div>Sub Total</div>
                    {month !== 'all' && <div>Ust. Voranmeldung Teil 4 Abschnitt 26-28 →</div>}
                    <div>{Dinero({amount: data.vatCharged[rate].subTotal, currency: 'EUR'}).toFormat()}</div>
                  </div>
                  <div className="billing-item">
                    <div>Taxes</div>
                    <div>{Dinero({amount: data.vatCharged[rate].taxes, currency: 'EUR'}).toFormat()}</div>
                  </div>
                  <div className="billing-item">
                    <div>Total</div>
                    <div>{Dinero({amount: data.vatCharged[rate].total, currency: 'EUR'}).toFormat()}</div>
                  </div>
                </div>))}

                {Object.keys(data.vatCharged).length === 0 && <div>
                  ℹ️ No orders with VAT charged in this timeframe.
                  {month !== 'all' && <>This concerns Ust. Voranmeldung Teil 4 Abschnitt 26-28 for this month.</>}
                </div>}

                <h2>Orders from VAT exempt businesses</h2>
                <p>Count: {data.exemptBusiness.orderVolume}</p>
                <div className="billing-item">
                  <div>Sub Total</div>
                  {month !== 'all' && <div>Ust. Voranmeldung Teil 3 Abschnitt 20 →</div>}
                  <div>{Dinero({amount: data.exemptBusiness.subTotal, currency: 'EUR'}).toFormat()}</div>
                </div>
                <div className="billing-item">
                  <div>Taxes</div>
                  <div>{Dinero({amount: data.exemptBusiness.taxes, currency: 'EUR'}).toFormat()}</div>
                </div>
                <div className="billing-item">
                  <div>Total</div>
                  <div>{Dinero({amount: data.exemptBusiness.total, currency: 'EUR'}).toFormat()}</div>
                </div>

                <h2>Orders From abroad (vat exempt)</h2>
                <p>Count: {data.exemptExport.orderVolume}</p>
                <div className="billing-item">
                  <div>Sub Total</div>
                  {month !== 'all' && <div>Ust. Voranmeldung Teil 6 Zeile 21 →</div>}
                  <div>{Dinero({amount: data.exemptExport.subTotal, currency: 'EUR'}).toFormat()}</div>
                </div>
                <div className="billing-item">
                  <div>Taxes</div>
                  <div>{Dinero({amount: data.exemptExport.taxes, currency: 'EUR'}).toFormat()}</div>
                </div>
                <div className="billing-item">
                  <div>Total</div>
                  <div>{Dinero({amount: data.exemptExport.total, currency: 'EUR'}).toFormat()}</div>
                </div>


                <h2>Displayed Date Range</h2>
                <div>Start Date: {startDate && startDate.format('LLL')}</div>
                <div>End Date: {endDate && endDate.format('LLL')}</div>

                <h2>List of orders from VAT Exempt Businesses (Zusammenfassende Meldung)</h2>
                {data.exemptBusinessList && data.exemptBusinessList.length > 0 &&
                data.exemptBusinessList.map(item => (
                  <div className="billing-item">
                    <div>{item.vatCode}</div>
                    <div>{Dinero({amount: item.total, currency: 'EUR'}).toFormat()}</div>
                  </div>
                ))
                }
              </>
            }
          </div>}
      </div>
    )
  }

}

export default AccountingTally;
