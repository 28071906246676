import React from 'react';
import {Link} from "react-router-dom";

class TabItem extends React.Component {

  render() {
    const {children, color, link} = this.props;
    return (
      <Link to={link} className="tab-link">
        <div>
          <div className="corner-wrapper" style={{backgroundColor: color}}>
            <div className="top-corner-overlay"/>
          </div>
          <div className="tab-item" style={{backgroundColor: color}}>
            {children}
          </div>
          <div className="corner-wrapper" style={{backgroundColor: color}}>
            <div className="bottom-corner-overlay"/>
          </div>
        </div>
      </Link>
    )
  }
}

export default TabItem;
